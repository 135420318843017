import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { TreoModule } from '@treo';
import { TreoConfigModule } from '@treo/services/config';
import { TreoMockApiModule } from '@treo/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockDataServices } from 'app/data/mock';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { SharedService} from 'app/shared.service';
import { HttpClientModule} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FndetailsComponent } from './modules/admin/fund-notice/fndetails/fndetails.component';
import { FundInvestorinfoDetailsModule } from './modules/admin/fund-investorinfo/fund-investorinfo-details/fund-investorinfo-details.module';
import jsZip from 'jszip';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// window.pdfMake = pdfMake;
 //window.JSZip = jsZip;

import { DataTablesModule } from 'angular-datatables';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import 'datatables.net-buttons/js/buttons.print.js';


import * as $ from 'jquery';

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy       : PreloadAllModules,
    relativeLinkResolution   : 'legacy'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        MatStepperModule,MatFormFieldModule,MatButtonModule, MatButtonToggleModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),
        TreoMockApiModule.forRoot(mockDataServices),

        // Core
        CoreModule,

        // Layout
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),
        MatSnackBarModule,
        NgbModule,
        DataTablesModule

    ],
    exports: [
        MatStepperModule,MatFormFieldModule,MatButtonModule, MatButtonToggleModule
      ],
    providers : [SharedService],
        bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}


