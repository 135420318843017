<!-- Navigation -->
<treo-vertical-navigation class="bg-cool-gray-900 theme-dark"
                         *ngIf="isScreenSmall"
                         [appearance]="'classic'"
                         [mode]="'over'"
                         [name]="'mainNavigation'"
                         [navigation]="data.navigation.default"
                         [opened]="false">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <a class="link" [routerLink]="['/dashboard']"><img src="assets/images/logo/rule506_logo_long.png"></a>
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Logo -->
        <div class="logo"
             *ngIf="!isScreenSmall">
            <a class="link" [routerLink]="['/dashboard']"><img class="logo-text" src="assets/images/logo/rule506_logo_long.png"></a>
            <a class="link" [routerLink]="['/dashboard']"><img class="logo-base" src="assets/images/logo/rule506_logo_long.png"></a>
        </div>

        <!-- Horizontal navigation -->
        <treo-horizontal-navigation *ngIf="!isScreenSmall"
                                   [name]="'mainNavigation'"
                                   [navigation]="data.navigation.horizontal"></treo-horizontal-navigation>

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                *ngIf="isScreenSmall"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>

        <!-- Search -->
        <search [appearance]="'bar'"></search>

        <!-- Shortcuts -->
        <shortcuts [shortcuts]="data.shortcuts"></shortcuts>

        <!-- Messages -->
        <messages [messages]="data.messages"></messages>

        <!-- Notifications -->
        <notifications [notifications]="data.notifications"></notifications>

        <!-- User -->
        <user [user]="data.user"></user>

    </div>

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

    <!-- Footer -->
    <div class="footer">
        Rule 506+ &copy; {{currentYear}}
    </div>

</div>
