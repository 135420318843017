<div class="col-12 p-4 lt-xl:w-1/2">
    <div class="flex  flex-col flex-auto p-6 bg-card border-blue rounded border-l-4 shadow-md ">
        <table class="tbl_fundsummary">
            <tbody *ngFor="let dataItem of FundSummaryList" class='mb-4'>
                <tr>
                    <td colspan=5>
                        <h3 class='mt-1'>{{dataItem.FM_Name}} Snapshot</h3>
                    </td>
                </tr>
                <tr>
                    <td class="pr-3"><strong>US Investors</strong></td>
                    <td class="pr-3">{{dataItem.US_Investors}}</td>
                    <td class="pr-3"><strong>US Amount</strong></td>
                    <td class="pr-3">$</td>
                    <td class="pr-3 text-right">{{dataItem.US_Amount | number:'1.2-2'}}</td>
                </tr>
                <tr>
                    <td class="pr-3"><strong>Non-US Investors</strong></td>
                    <td class="pr-3">{{dataItem.Non_US_Investors}}</td>
                    <td class="pr-3"><strong>Non-US Amount</strong></td>
                    <td class="pr-3">$</td>
                    <td class="pr-3 text-right">{{dataItem.Non_US_Amount | number:'1.2-2'}}</td>
                </tr>
                <tr>
                    <td class="pr-3"><strong>Total Investors</strong></td>
                    <td class="pr-3">{{dataItem.Investors}}</td>
                    <td class="pr-3"><strong>Total Amount</strong></td>
                    <td class="pr-3">$</td>
                    <td class="pr-3 text-right">{{dataItem.Amount | number:'1.2-2'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>