import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Notice } from 'app/modules/admin/fund-notice/notice.types';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  readonly API_URL = environment.apiUrl;
  private _notice: BehaviorSubject<Notice | null>;
  private _notices: BehaviorSubject<Notice[] | null>;
  NoticesListResponse: any = [];

  constructor(private http: HttpClient) {
    this._notice = new BehaviorSubject(null);
    this._notices = new BehaviorSubject(null);
  }

  /**
   * Getter for notice
   */
  get notice$(): Observable<Notice> {
    return this._notice.asObservable();
  }

  /**
   * Getter for noticess
   */
  get notices$(): Observable<Notice[]> {
    return this._notices.asObservable();
  }

  getEventLoggerList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/eventlogger');
  }

  getFundSummaryList(broker: any, cik: any) {
    return this.http.get(this.API_URL + '/fundsummary/' + broker + '/' + cik + '');
  }

  getFundSummaryTop5(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/dashboardfs');
  }

  getTasksTop5Upcoming(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/dashboardtu5');
  }

  getTasksTop5Done(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/dashboardtd5');
  }

  getTasksTop5Expired(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/dashboardte5');
  }


  getFundInvestorList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/fundinvestorinfo');
  }

  getFundSummaryID(val: any) {
    return this.http.get(this.API_URL + '/fundsummary/' + val);
  }

  getFundMasterID(val: any) {
    return this.http.get(this.API_URL + '/fundmaster/' + val);
  }

  getFundBrokerID(val: any) {
    return this.http.get(this.API_URL + '/fundbroker/' + val);
  }

  deleteFundBrokerID(val: any) {
    return this.http.delete(this.API_URL + '/fundbroker/' + val);
  }

  getFundInvestorInfoID(val: any) {
    return this.http.get(this.API_URL + '/fundinvestorinfo/' + val);
  }

  updateFundInvestorInfo(val: any) {
    return this.http.post(this.API_URL + '/fundinvestorinfo', val);
  }

  deleteFundInvestorInfo(val: any) {
    return this.http.delete(this.API_URL + '/fundinvestorinfo/' + val);
  }

  getFundNoticeID(val: any, invest: any, latest: any) {
    return this.http.get(this.API_URL + '/fundnotice/' + val + '/' + invest + '/' + latest + '');
  }

  getFundNoticeID1(val: any): Observable<Notice[]> {
    let UnsortedResponse = [];
    let SortedResponse = [];
    return this.http.get<Notice[]>(this.API_URL + '/fundnotice/' + val).pipe(
      tap((response) => {
        /*  Map respinse to model */
        UnsortedResponse = response;
        let count = 0;
        UnsortedResponse.forEach(value => {
          count++;
          let myNode =
          {
            FN_ID: Number(value.FN_ID),
            FN_FM_ID: Number(value.FN_FM_IDn),
            FN_State: Number(value.FN_State),
            FN_Number: Number(value.FN_Number),
            FN_Type_Filing: Number(value.FN_Type_Filing),
            FN_FilingDate: value.FN_FilingDate,
            FN_Exemption: value.FN_Exemption,
            FN_FilingDoc: value.FN_FilingDoc,
            FN_StateCorresDocID: value.FN_StateCorresDocID,
            FN_Notes: value.FN_Notes,
            S_Name: value.S_Name,
            FT_Desc: value.FT_Desc,
          }
          SortedResponse.push(myNode);
        })
        let total = SortedResponse.length;
        let date: Date = new Date();
        this.NoticesListResponse = SortedResponse.sort((a, b) => a.id > b.id ? -1 : 1)
        this._notices.next(this.NoticesListResponse);
      })
    );
  }

  updateFundNotice(val: any) {
    return this.http.post(this.API_URL + '/fundnotice', val);
  }

  deleteFundNotice(val: any) {
    return this.http.post(this.API_URL + '/fundnoticed/', val);
  }

  getAddressID(val: any) {
    return this.http.get(this.API_URL + '/address/' + val);
  }


  getFundMasterList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/fundmaster');
  }


  getIndustryGroupList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/industrygroup');
  }

  getFederalExclusionList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/federalexclusion');
  }

  getCountryList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/country');
  }

  getStatesList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/states');
  }

  getFilingTypeList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/FilingType');
  }

  getCurrencyList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/currency');
  }

  getEntityTypeList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/entitytype');
  }

  getBrokersList(): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/brokers');
  }

  getBrokersReverseList(val: any): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/brokerfundbrokerreverse/' + val);
  }

  getTasksList(val: any): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/Tasks/' + val);;
  }

  getTaskSectionsList(val: any): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/TaskSections/' + val);
  }

  registerUser(val: any) {
    return this.http.post(this.API_URL + '/users', val);
  }

  updateUser(val: any) {
    return this.http.post(this.API_URL + '/usersupdate', val);
  }
  getUsersID(val: any): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/Userprofile/' + val);;
  }

  InsertFundBroker(val: any) {
    return this.http.post(this.API_URL + '/fundbroker', val);
  }


  insertUpdateStep1FundMaster(val: any) {
    return this.http.post(this.API_URL + '/fundmaster', val);
  }

  insertUpdateStep2FundMaster(val: any) {
    return this.http.post(this.API_URL + '/address', val);
  }

  insertUpdateStep3FundMaster(val: any) {
    return this.http.post(this.API_URL + '/fundmaster', val);
  }

  insertUpdateStep4FundMaster(val: any) {
    return this.http.post(this.API_URL + '/fundmaster', val);
  }
  validateLogin(val: any) {
    return this.http.post(this.API_URL + '/users/Login', val);
  }
  uploadUserProfileImgUpload(val: any) {
    return this.http.post(environment.configurationsURL + '/UserProfileImgUpload/FileUploads/', val);
  }

  getFnDetailsID(val: any): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/fndetails/' + val);;
  }

  getFundInvestorInfoDetailsID(val: any): Observable<any[]> {
    return this.http.get<any>(this.API_URL + '/fundinvestorinfodetails/' + val);;
  }

}