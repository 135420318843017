<div class="content-layout fullwidth-basic-normal-scroll">

    <treo-card class="auth-card fullscreen">

        <!-- Form container -->
        <div class="form-container">

            <div class="form">

                <!-- Title -->
                <div class="title">Sign in</div>
                <div class="subtitle">
                    <span>Don't have an account?</span>
                    <a class="link"
                       [routerLink]="['/sign-up']">Sign up</a>
                </div>

                <!-- Message -->
                <treo-message *ngIf="message"
                             [appearance]="message.appearance"
                             [showIcon]="message.showIcon"
                             [type]="message.type"
                             [@shake]="message.shake">
                    {{message.content}}
                </treo-message>

                <!-- Sign in form -->
                <form [formGroup]="signInForm">

                    <!-- Email field -->
                    <mat-form-field>
                        <mat-label>Email address</mat-label>
                        <input id="email"
                               matInput
                               [formControlName]="'email'">
                    </mat-form-field>

                    <!-- Password field -->
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input id="password"
                               matInput
                               type="password"
                               [formControlName]="'password'"
                               #passwordField>
                        <button mat-icon-button
                                type="button"
                                (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'"
                                matSuffix>
                            <mat-icon *ngIf="passwordField.type === 'password'"
                                      [svgIcon]="'visibility'"></mat-icon>
                            <mat-icon *ngIf="passwordField.type === 'text'"
                                      [svgIcon]="'visibility_off'"></mat-icon>
                        </button>
                    </mat-form-field>

                    <!-- Field footer -->
                    <div class="field-footer">
                        <div class="remember-me">
                            <mat-checkbox [color]="'primary'"
                                          [formControlName]="'rememberMe'">
                                Remember me
                            </mat-checkbox>
                        </div>
                        <a class="link"
                           [routerLink]="['/forgot-password']">Forgot password?</a>
                    </div>

                    <!-- Submit button -->
                    <button class="submit-button treo-mat-button-large"
                            mat-flat-button
                            [color]="'primary'"
                            [disabled]="signInForm.disabled"
                            (click)="signIn()">
                        <span *ngIf="!signInForm.disabled">
                            Sign in
                        </span>
                        <mat-progress-spinner *ngIf="signInForm.disabled"
                                              [diameter]="24"
                                              [mode]="'indeterminate'"></mat-progress-spinner>
                    </button>

                    <!-- Single sign-on -->
                    <div class="sso">
                        <div class="separator">
                            Or continue with
                        </div>
                        <div class="buttons">
                            <button class="sso-button"
                                    type="button"
                                    mat-stroked-button>
                                <mat-icon [svgIcon]="'feather:facebook'"></mat-icon>
                            </button>
                            <button class="sso-button"
                                    type="button"
                                    mat-stroked-button>
                                <mat-icon [svgIcon]="'feather:twitter'"></mat-icon>
                            </button>
                            <button class="sso-button"
                                    type="button"
                                    mat-stroked-button>
                                <mat-icon [svgIcon]="'feather:github'"></mat-icon>
                            </button>
                        </div>
                    </div>

                </form>

            </div>

        </div>

        <!-- Content container -->
        <div class="form-container">

            <div class="content">
                <!-- Logo -->
                <div class="logo">
                    <img src="assets/images/logo/rule506_logo.png">
                </div>
            </div>

        </div>

    </treo-card>

</div>
