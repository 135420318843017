/* tslint:disable:max-line-length */
import { TreoNavigationItem } from '@treo/components/navigation';

export const defaultNavigation: TreoNavigationItem[] = [
 
    {
        id      : 'fund',
        title   : 'Funds',
        type    : 'basic',
        link    : '/fundsummary',
        icon    : 'feather:trending-up'
    },
    {
        id   : 'tasks',
        title: 'Tasks',
        type : 'basic',
        link : '/tasks',
        icon : 'heroicons_solid:inbox-in',
    }
];
export const compactNavigation: TreoNavigationItem[] = [
    {
        id      : 'fund',
        title   : 'Funds',
        type    : 'aside',
        link    : '/fundsummary',
        icon    : 'feather:trending-up',
    },
    {
        id   : 'tasks',
        title: 'Tasks',
        type : 'aside',
        link : '/tasks',
        icon : 'heroicons_solid:inbox-in',
    }
];
export const futuristicNavigation: TreoNavigationItem[] = [
    {
        id      : 'fund',
        title   : 'Funds',
        type    : 'basic',
        link    : '/fundsummary',
        icon    : 'feather:trending-up'
    },
    {
        id   : 'tasks',
        title: 'Tasks',
        type : 'basic',
        link : '/tasks',
        icon : 'heroicons_solid:inbox-in',
    }
];
export const horizontalNavigation: TreoNavigationItem[] = [
    {
        id      : 'fund',
        title   : 'Funds',
        type    : 'basic',
        link    : '/fundsummary',
        icon    : 'feather:trending-up'
    },
    {
        id   : 'tasks',
        title: 'Tasks',
        type : 'basic',
        link : '/tasks',
        icon : 'heroicons_solid:inbox-in',
    }
];
