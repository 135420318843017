import { Component, OnInit, Directive, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { SharedService } from 'app/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import $ from "jquery";
import { CommonComponent } from '../../common/common.component';
import { formatNumber } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fund-snapshot',
  templateUrl: './fund-snapshot.component.html',
  styleUrls: ['./fund-snapshot.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FundsnapshotComponent implements OnInit {
  constructor(private service: SharedService,
    private _Activatedroute: ActivatedRoute,
  ) {
    this.ISError = false;
  }
  common: any = [];
  iFMID: number;
  ISError: boolean;
  FundSummaryList: any = [];

  ngOnInit(): void {
    this.common = new CommonComponent();
    //console.log('session fm_id' + Number(sessionStorage.getItem('FM_ID')));
    this.iFMID = Number(this._Activatedroute.snapshot.paramMap.get("id"));
    if (this.iFMID ) {
     // console.log(' fm_id A ' + this.iFMID );
    } else {
      this.iFMID = Number(sessionStorage.getItem('FM_ID'));
      //console.log(' fm_id B ' + this.iFMID );
    }
    

    this.GetFundSummaryID(this.iFMID);
  }

  GetFundSummaryID(FM_ID: any) {
    this.service.getFundSummaryID(FM_ID).subscribe((data: any[]) => {
      this.FundSummaryList = data;
    }
    )
  }
}
