import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FundsnapshotComponent } from 'app/modules/admin/fund-snapshot/fund-snapshot.component';

@NgModule({
    declarations: [
        FundsnapshotComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FundsnapshotComponent
    ]
})
export class SharedModule
{
}
