import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common',
  template: `<div>
  <p>Common</p>
</div>`,
})
export class CommonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showLoader(){
    var element = document.getElementById("custom-loader");
    element.classList.remove("d-none");
  }

  hideLoader(){
    var element = document.getElementById("custom-loader");
    element.classList.add("d-none");
  }

  isEmpty(val){
    if(val===null || val==='null' || val == '' || val==undefined || val=='undefined' || val===0 || val=='0') {
      return true;
    } else {
      return false;
    }
  }

  RoundValue(num, dec) {
    let output = Number(num).toFixed(dec);
    return output;
  }

  currencyFormat(num, dec) {
    let fourdigits = Number(num).toFixed(dec);
    let splitnumber = String(fourdigits).split('.');
    let numbertoformat = splitnumber[0];   
    let putcommas = numbertoformat.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    let output = putcommas + '.' + splitnumber[1];
    return output;
  }

  currencyDecode(val){
    let newStr = val.replace(/,/g,'');
    return newStr
  }

}
