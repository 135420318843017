import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthForgotPasswordComponent } from 'app/modules/auth/forgot-password/forgot-password.component';
import { AuthSignInComponent } from 'app/modules/auth/sign-in/sign-in.component';
import { InitialDataResolver } from 'app/app.resolvers';


// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'Home/Index'},

    // Redirect signed in user to the '/example'
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'Home/Index'},

   /* {
        path: 'fundsummary',
        //canActivate: [NoAuthGuard],
        //canActivateChild: [NoAuthGuard],
        component: FundSummaryComponent
    },*/
    {
        path: 'forgot-password',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: AuthForgotPasswordComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
        ]
    },

    {
        path: 'Home/Index',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: AuthSignInComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes (guest)
    {
        path: 'sign-in',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: AuthSignInComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) }
        ]
    },

    // Auth routes (logged in)
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [

            // Dashboard
            {path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule)},
            // tasks
            {path: 'tasks', loadChildren: () => import('app/modules/admin/tasks/tasks.module').then(m => m.TasksModule)},
            // Fundsummary
            {path: 'fundsummary', loadChildren: () => import('app/modules/admin/fund-summary/fund-summary.module').then(m => m.FundSummaryModule)},
            {path: 'fundsummary1', loadChildren: () => import('app/modules/admin/fund-summary/fund-summary.module').then(m => m.FundSummaryModule)},
            {path: 'fundsummary2', loadChildren: () => import('app/modules/admin/fund-summary/fund-summary.module').then(m => m.FundSummaryModule)},
            // Fundmaster
            {path: 'fundmaster/:id', loadChildren: () => import('app/modules/admin/fund-master/fund-master.module').then(m => m.FundmasterModule)},
            //FundInvestorInfo
            {path: 'fundinvestorinfo/:id', loadChildren: () => import('app/modules/admin/fund-investorinfo/fund-investorinfo.module').then(m => m.FundInvestorInfoModule)},
            {path: 'fundinvestorinfo/fundinvestorinfodetails/:id', loadChildren: () => import('app/modules/admin/fund-investorinfo/fund-investorinfo-details/fund-investorinfo-details.module').then(m => m.FundInvestorinfoDetailsModule)},
            //FundNotice
            {path: 'fundnotice/:id', loadChildren: () => import('app/modules/admin/fund-notice/fund-notice.module').then(m => m.FundNoticeModule)},
            {path: 'fundnotice1/:id', loadChildren: () => import('app/modules/admin/fund-notice/fund-notice.module').then(m => m.FundNoticeModule)},
            {path: 'fundnotice2/:id', loadChildren: () => import('app/modules/admin/fund-notice/fund-notice.module').then(m => m.FundNoticeModule)},
            //FundNotice Details
            {path: 'fundnotice/fndetails/:id', loadChildren: () => import('app/modules/admin/fund-notice/fndetails/fndetails.module').then(m => m.FndetailsModule)},
            // Register
            {path: 'register', loadChildren: () => import('app/modules/admin/register/register.module').then(m => m.RegisterModule)},
            // Profile
            {path: 'profile', loadChildren: () => import('app/modules/admin/profile/profile.module').then(m => m.ProfileModule)}
            // 404 & Catch all
            // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
            // {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
